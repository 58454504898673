<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import type { ILeaseRegisterLease } from '@register'

interface Props {
  lease: ILeaseRegisterLease
  documentId: string
}
const props = defineProps<Props>()

defineEmits(['close', 'confirm'])

const _documentId = ref(unref(props.documentId))
const options = computed(
  () =>
    props.lease.documents?.map(({ id, name, filename }) => ({
      label: name ?? filename,
      value: id,
    })) ?? [],
)
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="{
      enterActiveClass: 'transition duration-300 ease-in-out transform',
      enterFromClass: '-translate-y-6 opacity-0',
      enterToClass: 'translate-y-0 opacity-100',
      leaveActiveClass: 'transition duration-300 ease-in-out transform',
      leaveToClass: 'translate-y-6 opacity-0',
      leaveFromClass: '-translate-y-0 opacity-100',
    }"
  >
    <ModalContainer
      title="Change Lease Document"
      close-on-click
      @close="$emit('close')"
    >
      <div>
        <FormKit
          v-model="_documentId"
          type="listbox"
          :options="options"
          label="Select the lease document"
          validation="required"
        />
      </div>

      <div class="mt-6 flex justify-end gap-2">
        <Button
          color="transparentSecondary"
          class="mr-2"
          @click.prevent="$emit('close')"
        >
          Cancel
        </Button>

        <Button type="button" @click="$emit('confirm', _documentId)">
          Change
        </Button>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
